@use "./variables" as *;
@use 'components/carousel';

@tailwind base;
@tailwind components;
@tailwind utilities;


h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  letter-spacing: -0.015em;
  line-height: 1.2;

  .block--text & {
    margin-bottom: 0.75rem;
  }
}

h1 {
  font-size: $font-size-h1;


  & + ul,
  & + p {
    margin-top: calc(#{$font-size-h1} / 2.3);
  }
}

h2 {
  font-size: $font-size-h3;
  font-weight: 500;

  & + ul,
  & + p {
    margin-top: calc(#{$font-size-h3} / 2.3);
  }

  @screen lg {
    font-size: $font-size-h2;

    & + ul,
    & + p {
      margin-top: calc(#{$font-size-h2} / 2.3);
    }
  }

}

h3 {
  font-size: $font-size-h3;

  & + ul,
  & + p {
    margin-top: calc(#{$font-size-h3} / 2.3);
  }
}

h4 {
  font-size: $font-size-h4;

  & + ul,
  & + p {
    margin-top: calc(#{$font-size-h4} / 2.3);
  }
}

h5 {
  font-size: $font-size-h5;

  & + ul,
  & + p {
    margin-top: calc(#{$font-size-h5} / 2.3);
  }
}

h6 {
  font-size: $font-size-h6;

  & + ul,
  & + p {
    margin-top: calc(#{$font-size-h6} / 2.3);
  }
}

p {
  line-height: 1.5;
  font-family: $font-family-base;
  font-size: 1.125rem;
  letter-spacing: 0.015em;

  & + p {
    margin-top: calc(#{$font-size-base} * 1.5);
  }

  & + .button {
    margin-top: 1.5rem;
  }
}

a {
  @apply transition duration-300 ease-in-out underline;
}

ul {
  list-style-type: none;
  padding: 0;

  li {
    position: relative;
    margin-bottom: 12px;
    margin-left: 1.8rem;

    &:before {
      content: '→';
      position: absolute;
      left: -1.8rem;
    }
  }
}

.button {
  &-base {
    @apply inline-block w-fit font-sec leading-normal no-underline px-button-x py-button-y rounded-button transition duration-300
  }

  &-primary {
    @apply button-base border-highlight border bg-highlight font-normal
    hover:text-light hover:bg-primary hover:border-secondary;
  }

  &-secondary {
    @apply button-base border-secondary border bg-secondary font-normal
    hover:text-light hover:bg-primary hover:border-primary;
  }
}

input[type="file"]::file-selector-button {
  @apply bg-primary;
  &:hover {
    @apply bg-highlight;
    @apply text-primary;
  }
}

header {
  @apply relative;
  &::after {
    content: "";
    @apply absolute bg-secondary-pattern bg-auto bg-repeat-x bg-center left-[0px] bottom-[-24px] h-[25px] right-0;
  }
}

section {
  &.section2 {
    @apply relative;
    &::before {
      content: "";
      @apply absolute bg-primary-pattern bg-auto bg-repeat-x bg-center left-[0px] top-[-24px] h-[25px] right-[0px] rotate-180;
    }
    &::after {
      content: "";
      @apply absolute bg-primary-pattern bg-auto bg-repeat-x bg-center left-[0px] bottom-[-24px] h-[25px] right-0;
    }
  }
  &.section3 {
    @apply relative;
    &::before {
      content: "";
      @apply absolute bg-secondary-pattern bg-auto bg-repeat-x bg-center left-[0px] top-[-24px] h-[25px] right-[0px] rotate-180;
    }
  }
}