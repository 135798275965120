@charset "UTF-8";
.swiper .swiper-wrapper .swiper-slide {
  width: auto;
}
.block__type__featured-testimonials-slideshow .swiper {
  height: initial;
}
.block__type__featured-testimonials-slideshow .swiper .swiper-wrapper .swiper-slide {
  width: 100%;
}
@screen lg {
  .block__type__featured-testimonials-slideshow .swiper .swiper-wrapper .swiper-slide {
    width: calc(50% - 15px);
  }
}
.block__type__horizontal-timeline-slider .swiper {
  height: initial;
  overflow: visible !important;
}
.block__type__horizontal-timeline-slider .swiper .swiper-wrapper {
  max-height: initial;
}
.block__type__horizontal-timeline-slider .swiper .swiper-wrapper .swiper-slide {
  width: calc(100% - 20px);
}
@screen lg {
  .block__type__horizontal-timeline-slider .swiper .swiper-wrapper .swiper-slide {
    width: calc(33.3333% - 20px);
  }
}
.swiper-pagination-ga {
  @apply bg-primary;
}
.has--bg-primary .swiper-pagination-ga {
  @apply bg-primary;
}
.has--bg-primary .swiper-pagination-ga {
  @apply bg-primary;
}
.swiper-pagination-ga .swiper-pagination-bullet {
  padding: 0;
  margin: 0 !important;
  width: inherit;
  height: 2px;
  border-radius: 0;
  @apply bg-primary;
}
.has--bg-primary .swiper-pagination-ga .swiper-pagination-bullet, .has--bg-primary .swiper-pagination-ga .swiper-pagination-bullet {
  @apply bg-highlight;
}

@screen lg {
  .no-container .block__no-mirror .swiper-navigation-ga {
    @apply me-10;
  }
}
.has--bg-primary .swiper-navigation-ga .swiper-button-prev-ga svg, .has--bg-primary .swiper-navigation-ga .swiper-button-prev-ga svg,
.has--bg-primary .swiper-navigation-ga .swiper-button-next-ga svg,
.has--bg-primary .swiper-navigation-ga .swiper-button-next-ga svg {
  @apply fill-highlight hover:fill-light;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  letter-spacing: -0.015em;
  line-height: 1.2;
}
.block--text h1, .block--text h2, .block--text h3, .block--text h4, .block--text h5, .block--text h6 {
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 3.375rem;
}
h1 + ul, h1 + p {
  margin-top: calc(3.375rem / 2.3);
}

h2 {
  font-size: 2rem;
  font-weight: 500;
}
h2 + ul, h2 + p {
  margin-top: calc(2rem / 2.3);
}
@screen lg {
  h2 {
    font-size: 2.5rem;
  }
  h2 + ul, h2 + p {
    margin-top: calc(2.5rem / 2.3);
  }
}

h3 {
  font-size: 2rem;
}
h3 + ul, h3 + p {
  margin-top: calc(2rem / 2.3);
}

h4 {
  font-size: 1.6875rem;
}
h4 + ul, h4 + p {
  margin-top: calc(1.6875rem / 2.3);
}

h5 {
  font-size: 1.375rem;
}
h5 + ul, h5 + p {
  margin-top: calc(1.375rem / 2.3);
}

h6 {
  font-size: 1.125rem;
}
h6 + ul, h6 + p {
  margin-top: calc(1.125rem / 2.3);
}

p {
  line-height: 1.5;
  font-family: "Hind", sans-serif;
  font-size: 1.125rem;
  letter-spacing: 0.015em;
}
p + p {
  margin-top: calc(1.125rem * 1.5);
}
p + .button {
  margin-top: 1.5rem;
}

a {
  @apply transition duration-300 ease-in-out underline;
}

ul {
  list-style-type: none;
  padding: 0;
}
ul li {
  position: relative;
  margin-bottom: 12px;
  margin-left: 1.8rem;
}
ul li:before {
  content: "→";
  position: absolute;
  left: -1.8rem;
}

.button-base {
  @apply inline-block w-fit font-sec leading-normal no-underline px-button-x py-button-y rounded-button transition duration-300;
}
.button-primary {
  @apply button-base border-highlight border bg-highlight font-normal
    hover:text-light hover:bg-primary hover:border-secondary;
}
.button-secondary {
  @apply button-base border-secondary border bg-secondary font-normal
    hover:text-light hover:bg-primary hover:border-primary;
}

input[type=file]::file-selector-button {
  @apply bg-primary;
}
input[type=file]::file-selector-button:hover {
  @apply bg-highlight;
  @apply text-primary;
}

header {
  @apply relative;
}
header::after {
  content: "";
  @apply absolute bg-secondary-pattern bg-auto bg-repeat-x bg-center left-[0px] bottom-[-24px] h-[25px] right-0;
}

section.section2 {
  @apply relative;
}
section.section2::before {
  content: "";
  @apply absolute bg-primary-pattern bg-auto bg-repeat-x bg-center left-[0px] top-[-24px] h-[25px] right-[0px] rotate-180;
}
section.section2::after {
  content: "";
  @apply absolute bg-primary-pattern bg-auto bg-repeat-x bg-center left-[0px] bottom-[-24px] h-[25px] right-0;
}
section.section3 {
  @apply relative;
}
section.section3::before {
  content: "";
  @apply absolute bg-secondary-pattern bg-auto bg-repeat-x bg-center left-[0px] top-[-24px] h-[25px] right-[0px] rotate-180;
}