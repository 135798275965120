$font-family-base: "Hind", sans-serif;
$font-family-secondary: "Montserrat", sans-serif;
$font-family-tertiary: "Lora", sans-serif;

$font-size-base: 1.125rem;
$font-size-h1: 3.375rem;
$font-size-h2: 2.5rem;
$font-size-h3: 2rem;
$font-size-h4: 1.6875rem;
$font-size-h5: 1.375rem;
$font-size-h6: 1.125rem;