.swiper {

  .swiper-wrapper {

    .swiper-slide {
      width: auto;

      img {

      }
    }
  }

  .block__type__featured-testimonials-slideshow & {
    height: initial;

    .swiper-wrapper {
      .swiper-slide {

        width: 100%;
        @screen lg {
          width: calc(50% - 15px);
        }

      }
    }
  }

  .block__type__horizontal-timeline-slider & {
    height: initial;
    overflow: visible !important;

    .swiper-wrapper {
      max-height: initial;

      .swiper-slide {
        width: calc(100% - 20px);

        @screen lg {
          width: calc(33.3333% - 20px);
        }

        img {

        }
      }
    }
  }
}

.swiper-pagination-ga {
  @apply bg-primary;

  .has--bg-primary & {
    @apply bg-primary;
  }

  .has--bg-primary & {
    @apply bg-primary;
  }

  .swiper-pagination-bullet {
    padding: 0;
    margin: 0 !important;
    width: inherit;
    height: 2px;
    border-radius: 0;
    @apply bg-primary;

    .has--bg-primary &,
    .has--bg-primary & {
      @apply bg-highlight;
    }
  }

}

.swiper-navigation-ga {
  .block__no-mirror & {
    .no-container & {
      @screen lg {
        @apply me-10;
        //border: 1px solid red;
      }
    }
  }

  .swiper-button-prev-ga,
  .swiper-button-next-ga {
    svg {
      .has--bg-primary &,
      .has--bg-primary & {
        @apply fill-highlight hover:fill-light;
      }
    }
  }
}